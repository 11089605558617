import { useMemo } from 'react';
import { some } from 'lodash';
import { PromoContractsApi } from '@/api/domains/promo-contracts.api';
import { PromoContractsQueryKeys } from '@/api/domains/promo-contracts.query-keys';
import { useInfiniteLoader } from '@/hooks/useInfiniteLoader';
import { PromoContractDataGridRowData } from './types';
import { normalizeData } from '@/helpers/transformation.helpers';
import {
  usePromoContractsRecordFilterStatus,
  usePromoContractsStoreGridFilterPeriod,
  usePromoContractsStoreSearchQuery,
} from '../promo-contracts.store';
import { getPromoContractRecordStatus } from '../helpers/status-select.helpers';
import { PROMO_CONTRACT_STATUS_PAYMENT_CALCULATION } from '@/constants/promo-contract-status.constants';

const defaultPromoContracts: Array<PromoContractDataGridRowData> = [];

export const CONTRACTS_PER_PAGE = 200;

export const usePromoContracts = () => {
  const [startDate, endDate] = usePromoContractsStoreGridFilterPeriod();
  const searchQuery = usePromoContractsStoreSearchQuery();
  const recordFilterStatus = usePromoContractsRecordFilterStatus();

  const ensuredQuery = searchQuery.length >= 3 ? searchQuery : undefined;
  const recordStatuses = recordFilterStatus ? String(recordFilterStatus) : undefined;

  const queryInfiniteLoader = useInfiniteLoader({
    queryKey: PromoContractsQueryKeys.getContractsKey({
      date_from: startDate,
      date_to: endDate,
      query: ensuredQuery,
      record_statuses: recordStatuses,
    }),
    queryFn: async ({ offset }) => {
      const data = await PromoContractsApi.fetchPromoContracts({
        limit: CONTRACTS_PER_PAGE,
        offset,
        date_from: startDate,
        date_to: endDate,
        query: ensuredQuery,
        record_statuses: recordStatuses,
      });

      const contracts: Array<PromoContractDataGridRowData> = data.items.map(item => {
        const {
          start_date,
          end_date,
          document_number,
          contractor,
          record_status_id,
          id,
          specification_template,
          cashback_sum,
        } = item;
        const { tin, full_name: organizationName = '' } = contractor ?? {};

        const marketingOrgs = specification_template?.marketing_orgs?.map(marketingOrg => marketingOrg.full_name) ?? [];

        return {
          id,
          startDate: new Date(start_date),
          endDate: new Date(end_date),
          specificationNumber: document_number,
          organizationName,
          templateName: specification_template?.name || '',
          tin,
          recordStatus: getPromoContractRecordStatus(record_status_id),
          recordStatusId: record_status_id,
          paymentSum: cashback_sum,
          marketingOrgs: marketingOrgs,

          promoContract: item,
        };
      });

      const normalizedContracts = normalizeData(
        contracts,
        contractItem => ({
          id: contractItem.id,
          item: contractItem,
        }),
        ['data', 'indices'],
      );

      return {
        ...data,
        items: normalizedContracts,
      };
    },
    offsetPerPage: CONTRACTS_PER_PAGE,
    staleTime: Infinity,
  });

  const { data } = queryInfiniteLoader;

  const { loadedPagesLength, promoContracts, hasCashbackCalculationStatus } = useMemo(() => {
    const pageData = data?.pages;
    const loadedPagesLength = pageData?.length ?? 0;
    const promoContracts = pageData?.flatMap(({ items }) => items.data) || defaultPromoContracts;

    const hasCashbackCalculationStatus = some(
      promoContracts,
      contract => contract.recordStatusId === PROMO_CONTRACT_STATUS_PAYMENT_CALCULATION,
    );

    return {
      loadedPagesLength,
      promoContracts,
      hasCashbackCalculationStatus,
    };
  }, [data]);

  return {
    ...queryInfiniteLoader,
    promoContracts,
    loadedPagesLength,
    hasCashbackCalculationStatus,
  };
};
