import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledProfileMenuPopover = styled(Stack)(({ theme: { palette } }) => ({
  backgroundColor: palette.common.white,
  padding: 8,
}));

export const StyledProfileMenuPopoverHeader = styled(Stack)(() => ({
  borderBottom: '1px solid #E6EBF0',
  marginBottom: 8,
  padding: 8,
}));

export const StyledProfileMenuPopoverFooter = styled(Stack)(() => ({
  borderTop: '1px solid #E6EBF0',
  marginTop: 8,
  paddingTop: 8,
}));

export const StyledProfileMenuPopoverHeaderUserInfo = styled(Stack)(({ theme: { palette } }) => ({
  backgroundColor: palette.common.white,
  paddingLeft: 16,
}));

export const popoverSxStyle = {
  userName: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    height: '62px',
    justifyContent: 'center',
    letterSpacing: '0.5px',
    lineHeight: '24px',
  },

  contractorInfo: {
    color: '#637381',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '0.4px',
    lineHeight: '16px',
    whiteSpace: 'nowrap',

    name: {
      color: '#9AA7B5',
      marginTop: '8px',
    },
  },
} as const;
