import { useEffect, useRef, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import {
  Dialog,
  DialogContent,
  DialogActions,
  SliderOwnProps,
  Slider,
  Stack,
  styled,
  SvgIcon,
  DialogProps,
  useTheme,
  Breakpoint,
} from '@mui/material';
import AvatarEditor, { AvatarEditorProps } from 'react-avatar-editor';

import { ConfirmationDialogActionCancelButton, ConfirmationDialogActionProceedButton } from '@/components/dialogs';
import { ReactComponent as ImageSVG } from '@/assets/icons-2.0/image.svg';
import { getCanvasSize } from '@/helpers/image.helpers';
import { DialogHeader } from './elements.dialog';

type ImageCropDialog = Omit<DialogProps, 'onClose'> & {
  title?: string;
  isLoading: boolean;
  onClose: () => void;
  onEdit: (blob: Blob) => void;
  imageFile: AvatarEditorProps['image'] | null;
  variant?: 'circle' | 'square' | 'logo' | 'avatar' | '4:3';
  initialMinScale?: number;
  slotProps?: {
    avatarEditor?: Omit<AvatarEditorProps, 'image'>;
  };
};

const StyledSlider = styled(Slider)(({ theme }) => ({
  '.MuiSlider-track': {
    height: '8px',
    backgroundColor: theme.palette.secondary.main,
    border: 'none',
  },
  '.MuiSlider-thumb': {
    borderRadius: '12px',
    backgroundColor: theme.palette.secondary.main,
    height: '24px',
    width: '14px',
  },
  '.MuiSlider-rail': {
    backgroundColor: theme.palette.grey[600],
    height: '8px',
  },
}));

const MAX_CANVAS_HEIGHT = 600;
const AVATAR_AND_LOGO_SIZE = 450;
const DEFAULT_4_TO_3_SIZE = {
  width: 480,
  height: 360,
};
const MIN_SCALE = 1;
const MAX_SCALE = 2;
const DEFAULT_SLIDER_VALUE = 0;
const DIALOG_MAX_WIDTH_BREAKPOINT: Breakpoint = 'md';

export const ImageCropDialog = (props: ImageCropDialog) => {
  const {
    isLoading,
    onEdit,
    onClose,
    imageFile,
    variant,
    title = 'Загрузка изображения',
    slotProps,
    open,
    initialMinScale = MIN_SCALE,
    ...restOfDialogProps
  } = props;

  const theme = useTheme();
  const logoEditorRef = useRef<AvatarEditor>(null);
  const [sliderValue, setSliderValue] = useState<number>(DEFAULT_SLIDER_VALUE);
  const [scale, setScale] = useState<number>(initialMinScale);
  const [canvasWidth, setCanvasWidth] = useState<number>(0);
  const [canvasHeight, setCanvasHeight] = useState<number>(0);

  const isLogoOrAvatar = variant === 'logo' || variant === 'avatar';

  useUpdateEffect(() => {
    if (!open) {
      setSliderValue(DEFAULT_SLIDER_VALUE);
      setScale(initialMinScale);
    }
  }, [open]);

  useEffect(() => {
    if (!imageFile) return;

    const calcCanvasSize = async (maxWidth: number, maxHeight: number) => {
      if (isLogoOrAvatar || variant === 'square') {
        return { width: AVATAR_AND_LOGO_SIZE, height: AVATAR_AND_LOGO_SIZE };
      } else if (variant === '4:3') {
        return DEFAULT_4_TO_3_SIZE;
      } else {
        return await getCanvasSize(imageFile, maxWidth, maxHeight);
      }
    };

    const setCanvasSize = async () => {
      const maxWidth = theme.breakpoints.values[DIALOG_MAX_WIDTH_BREAKPOINT];
      const { width, height } = await calcCanvasSize(maxWidth, MAX_CANVAS_HEIGHT);
      setCanvasWidth(width);
      setCanvasHeight(height);
    };

    setCanvasSize();
  }, [imageFile]);

  if (!imageFile) {
    return null;
  }

  const handleSliderChange: SliderOwnProps['onChange'] = (_, value) => {
    if (typeof value === 'number') {
      setSliderValue(value);
      const newScale = initialMinScale + (value / 100) * (MAX_SCALE - initialMinScale);
      setScale(newScale);
    }
  };

  const handleSendButtonClick = () => {
    const canvas = isLogoOrAvatar ? logoEditorRef.current?.getImageScaledToCanvas() : logoEditorRef.current?.getImage();

    if (!canvas) {
      return;
    }

    canvas.toBlob(blob => {
      if (!blob) {
        return;
      }

      onEdit(blob);
    });
  };

  const isCircle = variant === 'circle' || variant === 'avatar' || variant === 'logo';

  return (
    <Dialog open={open} maxWidth={DIALOG_MAX_WIDTH_BREAKPOINT} onClose={onClose} {...restOfDialogProps}>
      <DialogHeader title={title} onClose={onClose} />

      <DialogContent sx={{ padding: 0 }}>
        <AvatarEditor
          ref={logoEditorRef}
          image={imageFile}
          width={canvasWidth}
          height={canvasHeight}
          color={[0, 0, 0, 0.4]}
          border={0}
          borderRadius={isCircle ? canvasWidth / 2 : 0}
          scale={scale}
          rotate={0}
          {...slotProps?.avatarEditor}
        />

        <Stack spacing={3} direction="row" sx={{ py: '12px', px: 5 }} alignItems="center">
          <SvgIcon component={ImageSVG} sx={{ fontSize: '16px' }} />
          <StyledSlider aria-label="Volume" value={sliderValue} onChange={handleSliderChange} disabled={isLoading} />
          <SvgIcon component={ImageSVG} sx={{ fontSize: '24px' }} />
        </Stack>
      </DialogContent>

      <DialogActions
        sx={{
          padding: theme => `${theme.spacing(2, 3)} !important`,
          borderTop: theme => theme.shape.divider,
        }}
      >
        <ConfirmationDialogActionCancelButton fullWidth={false} onClick={onClose} />
        <ConfirmationDialogActionProceedButton
          autoFocus
          fullWidth={false}
          loading={isLoading}
          onClick={handleSendButtonClick}
        >
          Отправить
        </ConfirmationDialogActionProceedButton>
      </DialogActions>
    </Dialog>
  );
};
