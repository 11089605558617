import { Stack, StackProps, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

export type ModalBreadcrumb = {
  name: string;
  /**
   * @deprecated Do not use this prop
   */
  icon?: React.ReactNode;
};

export type ModalBreadcrumbsProps = Omit<StackProps, 'children' | 'direction'> & {
  /**
   * @deprecated Do not use this prop
   */
  rootIcon?: React.ReactNode;
  breadcrumbs: ModalBreadcrumb[];
};

const StyledBreadcrumbsPaths = styled(Stack)(({ theme }) => ({
  flexGrow: 1,
  alignItems: 'center',
  height: theme.spacing(4),
  padding: theme.spacing(0, 1),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',

  svg: {
    width: 16,
    height: 16,
  },
})) as typeof Stack;

const StyledBreadcrumbsDivider = styled('div')(({ theme }) => ({
  color: alpha(theme.palette.grey[500], 0.48),
}));

export const ModalBreadcrumbs = (props: ModalBreadcrumbsProps) => {
  const { breadcrumbs } = props;

  return (
    <Stack direction="row" spacing={1} flexGrow={1}>
      <StyledBreadcrumbsPaths
        direction="row"
        spacing={1}
        divider={<StyledBreadcrumbsDivider>/</StyledBreadcrumbsDivider>}
      >
        {breadcrumbs.map(({ name }, index, { length }) => {
          const isLast = index === length - 1;

          return (
            <Typography
              key={index}
              noWrap
              component="div"
              variant="caption"
              color={isLast ? 'inherit' : 'text.disabled'}
              sx={{ maxWidth: isLast ? undefined : 184 }}
            >
              {name}
            </Typography>
          );
        })}
      </StyledBreadcrumbsPaths>
    </Stack>
  );
};
