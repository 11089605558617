import React, { useState } from 'react';
import { Box, ListItem, Paper, Stack, SvgIcon, SxProps, Theme, styled, useTheme } from '@mui/material';
import type { Transform } from '@dnd-kit/utilities';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { CSS } from '@dnd-kit/utilities';

import { ReactComponent as DragPanIcon } from '@/assets/icons-2.0/drag_pan.svg';
import { DeleteActionIconButton, EditActionIconButton } from '@/components/actions';
import { useGalleryStoreActions } from '../gallery.store';
import { LoadingIndicator } from '@/components/LoadingIndicator';
import { GalleryImageInfo } from '../types';

export type ImageCardProps = {
  dragging?: boolean;
  listeners?: SyntheticListenerMap;
  onRemove?: VoidFunction;
  transition?: string;
  transform?: Transform | null;
  wrapperStyle?: SxProps<Theme>;
  image: GalleryImageInfo;
  onEdit?: (image: GalleryImageInfo) => void;
  isOverlayImage?: boolean;
};

const ImageContainer = styled(Paper)(({ theme }) => ({
  color: theme.palette.text.secondary,
  height: '100%',
  width: '100%',
  minWidth: 159,
  minHeight: 159,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

type InlineMenuProps = {
  dragging?: boolean;
};

const InlineMenu = styled(Paper, {
  shouldForwardProp: prop => prop !== 'dragging',
})<InlineMenuProps>(({ theme, dragging }) => ({
  color: theme.palette.text.secondary,
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  padding: theme.spacing(0.5),
  margin: theme.spacing(0.5),
  transition: 'opacity .2s',
  opacity: 0,
  display: dragging ? 'none' : 'flex',
}));

export const ImageCard = React.forwardRef<HTMLDivElement, ImageCardProps>((props: ImageCardProps, ref) => {
  const {
    dragging,
    listeners,
    transition,
    transform,
    wrapperStyle,
    onRemove,
    onEdit,
    image,
    isOverlayImage,
    ...restProps
  } = props;

  const itemStyle = {
    transition,
    transform: transform ? CSS.Transform.toString(transform) : null,
    transformOrigin: '0 0',
    ...wrapperStyle,
  };

  const theme = useTheme();

  const { setSelectedImage } = useGalleryStoreActions();

  const deleteHandler = (image: GalleryImageInfo) => {
    setSelectedImage(image);
    onRemove?.();
  };
  const [isLoading, setIsLoading] = useState(true);

  const handleOnImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <Box
      component={ListItem}
      ref={ref}
      sx={{
        ...itemStyle,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        bgcolor: 'white',
        borderRadius: '12px',
        border: `1px solid ${theme.palette.neutral['300']}`,
        boxShadow: isOverlayImage ? 3 : 0,
        opacity: dragging ? 0.5 : 1,
        userSelect: 'none',
        padding: 0,
        position: 'relative',
        overflow: 'hidden',
        '&:hover .inline-menu': {
          opacity: dragging ? 0 : 1,
        },
      }}
      {...restProps}
    >
      <ImageContainer>
        {isLoading && <LoadingIndicator />}
        <Box
          component="img"
          src={image.url}
          onLoad={handleOnImageLoad}
          sx={{
            display: isLoading ? 'none' : 'block',
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'cover',
          }}
        />
      </ImageContainer>

      {!isOverlayImage && (
        <InlineMenu dragging={dragging} className="inline-menu" elevation={1} sx={{ top: 0, right: 0 }}>
          <Stack direction="row" spacing={1}>
            <EditActionIconButton onClick={() => onEdit?.(image)} />
            <DeleteActionIconButton onClick={() => deleteHandler(image)} />
          </Stack>
        </InlineMenu>
      )}
      {!isOverlayImage && (
        <InlineMenu
          dragging={dragging}
          className="inline-menu"
          elevation={1}
          sx={{ bottom: 0, right: 0, cursor: 'move' }}
          {...listeners}
        >
          <SvgIcon component={DragPanIcon} inheritViewBox sx={{ margin: 0.5 }} />
        </InlineMenu>
      )}
    </Box>
  );
});
