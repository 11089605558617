import { Navigate } from 'react-router-dom';
import { Suspense, useCallback } from 'react';
import {
  ContractDocumentPathParams,
  ContractDrugAnalyticsPathParams,
  ContractMarketingOrgPathParams,
  ContractPathParams,
  DorimPromoPaths,
  PlanogramTaskControlDetailPathParams,
} from '@/router/paths/dorim-promo.path';
import { ProgressBar } from '@/components/ProgressBar';
import PromoProductsPage from '@/pages/promo';
import ContractTemplatesPage from '@/pages/contract-templates';
import { PromoContractsPage } from '@/pages/promo-contracts';
import { PromoContractPage } from '@/pages/promo-contracts/contract';
import { MarketingOrgAgreementPage } from '@/pages/promo-contracts/marketing-org-agreement';
import { ContractDocumentPage } from '@/pages/promo-contracts/contract-document';
import { ContractDrugAnalyticsPage } from '@/pages/promo-contracts/analytics';
import { usePromoContract } from '@/hooks/usePromoContract';
import { BreadcrumbLink, LazyBreadcrumb, RouteObjectWithBreadcrumbs } from '@/components/Breadcrumbs';
import { useMarketingOrgAgreement } from '@/hooks/useMarketingOrgAgreement';
import { MarketingOrgAgreementResponse } from '@/api/domains/promo-contracts.api';
import { formatDrugName } from '@/helpers/format.helpers';
import { ContractsLayout } from '@/layouts/DorimPromo';
import { usePlanogramTask } from '@/pages/planogram-task/usePlanogramTask';
import { PlanogramTasksControlPage } from '@/pages/planogram-tasks-control';

export const DorimPromoRoute: RouteObjectWithBreadcrumbs = {
  path: DorimPromoPaths.ROOT_PATH,
  handle: {
    breadcrumb: {
      Component: () => <BreadcrumbLink to={DorimPromoPaths.ROOT_PATH}>Dorim.Promo</BreadcrumbLink>,
    },
  },
  children: [
    {
      index: true,
      element: <Navigate replace to={DorimPromoPaths.CONTRACT_TEMPLATES_PATH} />,
    },
    {
      path: DorimPromoPaths.PROMO_PRODUCTS_PATH,
      element: (
        <Suspense fallback={<ProgressBar />}>
          <PromoProductsPage />
        </Suspense>
      ),
    },
    {
      path: DorimPromoPaths.CONTRACT_TEMPLATES_PATH,
      element: (
        <Suspense fallback={<ProgressBar />}>
          <ContractTemplatesPage />
        </Suspense>
      ),
    },
    {
      path: DorimPromoPaths.CONTRACTS_PATH,
      element: <ContractsLayout />,
      handle: {
        breadcrumb: {
          Component: () => (
            <BreadcrumbLink to={DorimPromoPaths.CONTRACTS_PATH}>Спецификации с контрагентами</BreadcrumbLink>
          ),
        },
      },
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<ProgressBar />}>
              <PromoContractsPage />
            </Suspense>
          ),
        },
        {
          path: DorimPromoPaths.CONTRACT_PATH,
          handle: {
            breadcrumb: (params: ContractPathParams) => ({
              Component: () => {
                const { data, isLoading } = usePromoContract({
                  contractId: Number(params.contractId),
                });

                return (
                  <LazyBreadcrumb isLoading={isLoading}>
                    <BreadcrumbLink to={DorimPromoPaths.generateContractPath(params)}>
                      {data?.agreement?.document_number || 'Спецификация'}
                    </BreadcrumbLink>
                  </LazyBreadcrumb>
                );
              },
            }),
          },
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={<ProgressBar />}>
                  <PromoContractPage />
                </Suspense>
              ),
            },
            {
              path: DorimPromoPaths.CONTRACT_DOCUMENT_PATH,
              element: (
                <Suspense fallback={<ProgressBar />}>
                  <ContractDocumentPage />
                </Suspense>
              ),
              handle: {
                breadcrumb: (params: ContractDocumentPathParams) => ({
                  Component: () => (
                    <BreadcrumbLink to={DorimPromoPaths.generateContractDocumentPath(params)}>
                      Спецификация
                    </BreadcrumbLink>
                  ),
                }),
              },
            },
            {
              path: DorimPromoPaths.MARKETING_ORG_PATH,
              handle: {
                breadcrumb: (params: ContractMarketingOrgPathParams) => ({
                  Component: () => {
                    const { data, isLoading } = useMarketingOrgAgreement({
                      agreementId: Number(params.contractId),
                      marketingOrgId: Number(params.marketingOrgId),
                    });

                    return (
                      <LazyBreadcrumb isLoading={isLoading}>
                        <BreadcrumbLink to={DorimPromoPaths.generateMarketingOrgPath(params)}>
                          {data?.marketing_org_details?.marketing_org?.name || 'Маркетирующая организация'}
                        </BreadcrumbLink>
                      </LazyBreadcrumb>
                    );
                  },
                }),
              },
              children: [
                {
                  index: true,
                  element: (
                    <Suspense fallback={<ProgressBar />}>
                      <MarketingOrgAgreementPage />
                    </Suspense>
                  ),
                },
                {
                  path: DorimPromoPaths.ANALYTICS_PATH,
                  handle: {
                    breadcrumb: (params: ContractDrugAnalyticsPathParams) => ({
                      Component: () => {
                        const { data: analyticsDrug, isLoading } = useMarketingOrgAgreement({
                          agreementId: Number(params.contractId),
                          marketingOrgId: Number(params.marketingOrgId),
                          select: useCallback((data: MarketingOrgAgreementResponse | undefined) => {
                            return data?.marketing_org_details?.cashback_drugs?.find(
                              drug => drug.drug_id === Number(params.drugId),
                            );
                          }, []),
                        });

                        const breadcrumb = (title: string) => (
                          <LazyBreadcrumb isLoading={isLoading}>
                            <BreadcrumbLink to={DorimPromoPaths.generateContractPath(params)}>{title}</BreadcrumbLink>
                          </LazyBreadcrumb>
                        );

                        if (!analyticsDrug || !analyticsDrug.drug) {
                          return breadcrumb('Аналитика');
                        }

                        const { name, form, number } = analyticsDrug.drug;

                        return breadcrumb(formatDrugName(name, form, number));
                      },
                    }),
                  },
                  element: (
                    <Suspense fallback={<ProgressBar />}>
                      <ContractDrugAnalyticsPage />
                    </Suspense>
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: DorimPromoPaths.CONTRACT_DOCUMENT_PATH,
      element: (
        <Suspense fallback={<ProgressBar />}>
          <ContractDocumentPage />
        </Suspense>
      ),
    },
    {
      path: DorimPromoPaths.PLANOGRAM_TASKS_CONTROL,
      handle: {
        breadcrumb: {
          Component: () => (
            <BreadcrumbLink to={DorimPromoPaths.PLANOGRAM_TASKS_CONTROL}>
              Контроль заданий по фотофиксации
            </BreadcrumbLink>
          ),
        },
      },
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<ProgressBar />}>
              <PlanogramTasksControlPage />
            </Suspense>
          ),
        },
        {
          path: DorimPromoPaths.PLANOGRAM_TASK_CONTROL_DETAIL_PATH,
          lazy: async () => {
            const { PlanogramTaskPage } = await import(
              /* webpackChunkName: "PlanogramTaskPage" */
              '@/pages/planogram-task'
            );

            return {
              Component: () => (
                <Suspense fallback={<ProgressBar />}>
                  <PlanogramTaskPage />
                </Suspense>
              ),
            };
          },
          handle: {
            breadcrumb: (params: PlanogramTaskControlDetailPathParams) => ({
              Component: () => {
                const { data: task } = usePlanogramTask({
                  taskId: Number(params.taskId),
                });

                return (
                  <BreadcrumbLink to={DorimPromoPaths.PLANOGRAM_TASK_CONTROL_DETAIL_PATH}>
                    {task?.name || 'Задание'}
                  </BreadcrumbLink>
                );
              },
            }),
          },
        },
      ],
    },
  ],
};
