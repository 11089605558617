import { useState } from 'react';
import { Portal, useTheme } from '@mui/material';
import {
  useSensors,
  useSensor,
  PointerSensor,
  DndContextProps,
  DndContext,
  closestCenter,
  DragOverlay,
  DropAnimation,
  UniqueIdentifier,
  defaultDropAnimationSideEffects,
} from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { GridContainer } from './GridContainer';
import { ImageCard, ImageCardSortable } from './ImageCard';
import {
  useGalleryStoreIsContextWindowsOpen,
  useGalleryStoreActions,
  useGalleryStoreEditedFile,
  useGalleryStoreSelectedImage,
} from './gallery.store';
import { ImageCropDialog, useGlobalErrorStore } from '@/components/dialogs';
import { useUploadImage } from '@/hooks/useUploadImage';
import { getFirstBigImageWrapperStyle, reorderImages } from './gallery.helpers';
import { urlToFile } from '@/helpers/transformation.helpers';
import { GalleryImageInfo } from './types';
import { DeleteImageDialog } from './dialogs';
import { ImagePrefix } from '@/api/domains/image.api';

export type GalleryProps = {
  images: GalleryImageInfo[];
  onChange: (value: GalleryImageInfo[]) => void;
  onImagesEdit?: (images: GalleryImageInfo[]) => Promise<void>;
  onImageRemove?: (images: GalleryImageInfo[]) => Promise<void>;
  isLoading: boolean;
  imagePrefix: ImagePrefix;
};

type ImageOverlayProps = {
  activeId: UniqueIdentifier | null;
  images: GalleryImageInfo[];
  activeIndex: number;
};

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
};

const ImageOverlay = (props: ImageOverlayProps) => {
  const { activeId, activeIndex, images } = props;
  const theme = useTheme();

  return (
    <DragOverlay adjustScale dropAnimation={dropAnimationConfig} zIndex={theme.zIndex.modal + 1}>
      {activeId ? (
        <ImageCard
          image={images[activeIndex]}
          wrapperStyle={getFirstBigImageWrapperStyle(activeIndex)}
          isOverlayImage
        />
      ) : null}
    </DragOverlay>
  );
};

export const Gallery = (props: GalleryProps) => {
  const { images, onChange, onImagesEdit, onImageRemove, isLoading: isPassingLoading, imagePrefix } = props;

  const { setGlobalError } = useGlobalErrorStore();

  const { openDeleteDialog, openEditDialog, closeDeleteDialog, closeEditDialog, resetSelectedImage, setSelectedImage } =
    useGalleryStoreActions();
  const { isEditDialogOpen } = useGalleryStoreIsContextWindowsOpen();
  const selectedImage = useGalleryStoreSelectedImage();
  const editedFile = useGalleryStoreEditedFile();

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const getIndex = (id: number) => images.findIndex(image => image.id === id);
  const activeIndex = getIndex(Number(activeId));

  const sensors = useSensors(useSensor(PointerSensor));

  const { mutateAsync: uploadImage, isLoading } = useUploadImage();

  const editImages = async (image: Blob) => {
    try {
      const file = new File([image], `${imagePrefix}-image`, { type: `${image.type}` });
      const { url, token } = await uploadImage({ prefix: imagePrefix, file });

      let newImages: GalleryImageInfo[] = [];

      if (selectedImage) {
        const newImage: GalleryImageInfo = {
          ...selectedImage,
          url,
          token,
        };

        newImages = images.map(image => (image.id === selectedImage.id ? newImage : image));

        resetSelectedImage();
      } else {
        const newImage: GalleryImageInfo = {
          id: new Date().getTime(),
          url,
          token,
          position: images.length,
        };

        newImages = [...images, newImage];
      }

      await onImagesEdit?.(newImages);
      closeEditDialog();
    } catch (error) {
      setGlobalError(error);
    }
  };

  const editImage = async (image: GalleryImageInfo) => {
    setSelectedImage(image);

    try {
      const imageFile = await urlToFile({
        url: image.url,
        fileName: `${imagePrefix}-image-${image.id}`,
        fetchParams: { cache: 'no-cache' },
      });

      openEditDialog(imageFile);
    } catch (error) {
      setGlobalError(error);
    }
  };

  const removeImage = async () => {
    if (!selectedImage) return;

    const filteredImages = images
      .filter(image => image.id !== selectedImage.id)
      .map((image, index) => ({ ...image, position: index }));

    try {
      await onImageRemove?.(filteredImages);

      closeDeleteDialog();
      resetSelectedImage();
    } catch (error) {
      setGlobalError(error);
    }
  };

  const handleDragEnd: DndContextProps['onDragEnd'] = ({ over }) => {
    setActiveId(null);

    if (over) {
      const overIndex = getIndex(Number(over.id));
      if (activeIndex !== overIndex) {
        const newImages = reorderImages(images, activeIndex, overIndex);
        onChange(newImages);
      }
    }
  };

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={({ active }) => setActiveId(active.id)}
        onDragEnd={handleDragEnd}
        onDragCancel={() => setActiveId(null)}
      >
        <SortableContext items={images} strategy={rectSortingStrategy}>
          <GridContainer columns={4}>
            {images.map((image, index) => (
              <ImageCardSortable
                key={image.id}
                id={image.id}
                index={index}
                getWrapperStyle={getFirstBigImageWrapperStyle}
                onRemove={openDeleteDialog}
                image={image}
                onEdit={editImage}
              />
            ))}
          </GridContainer>
        </SortableContext>

        <Portal container={() => document.body}>
          <ImageOverlay activeIndex={activeIndex} activeId={activeId} images={images} />,
        </Portal>
      </DndContext>

      <ImageCropDialog
        open={isEditDialogOpen}
        title="Редактирование"
        imageFile={editedFile}
        isLoading={isLoading || isPassingLoading}
        onClose={closeEditDialog}
        onEdit={editImages}
        initialMinScale={0.5}
        variant="square"
      />
      <DeleteImageDialog onProceed={removeImage} onClose={closeDeleteDialog} isLoading={isPassingLoading} />
    </>
  );
};
