import { useState } from 'react';
import { Autocomplete, type AutocompleteProps } from '@mui/material';

import type { Contractor } from '@/api/domains/contractors.types';
import type { FetchContractorsParams } from '@/api/domains/contractors.api';

import { useContractorsSelect } from './useContractorsSelect';

export type ContractorSelectProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = Omit<AutocompleteProps<Contractor, Multiple, DisableClearable, FreeSolo>, 'options' | 'value'> & {
  fetchParams?: FetchContractorsParams;
  value?: Multiple extends true ? Contractor[] : Contractor | null;
  shouldInitialPreload?: boolean;
  idToExclude?: Contractor['id'];
};

export const ContractorSelect = <
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>(
  props: ContractorSelectProps<Multiple, DisableClearable, FreeSolo>,
) => {
  const { fetchParams, shouldInitialPreload, value, idToExclude, ...restProps } = props;

  const {
    isInitialLoading,
    options: contractorOptions,
    searchFn,
  } = useContractorsSelect({ fetchParams, shouldInitialPreload, idToExclude });

  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (_: React.SyntheticEvent, inputValue: string) => {
    setInputValue(inputValue);
    searchFn(inputValue);
  };

  const handlePaste: React.ClipboardEventHandler<HTMLInputElement> = event => {
    event.preventDefault();
    const clipboardData = event.clipboardData.getData('text');

    setInputValue(clipboardData.trim());
    searchFn(clipboardData.trim());
  };

  // Transform the value prop based on Multiple type
  const transformedValue = value ? (value as Multiple extends true ? Contractor[] : Contractor) : undefined;

  return (
    <Autocomplete
      autoComplete
      disablePortal
      openOnFocus
      includeInputInList
      inputValue={inputValue}
      loading={isInitialLoading}
      options={contractorOptions}
      value={transformedValue}
      getOptionLabel={(option: Contractor | string) =>
        typeof option === 'string' ? option : option.full_name || option.name || 'Без наименования'
      }
      isOptionEqualToValue={(option: Contractor | string) => {
        if (typeof option === 'string' && typeof value === 'string') {
          return option === value;
        }

        if (option && value && 'id' in (option as Contractor) && 'id' in value) {
          return (option as Contractor).id === (value as Contractor).id;
        }

        return false;
      }}
      onPaste={handlePaste}
      onInputChange={handleInputChange}
      {...restProps}
    />
  );
};
